// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "../channels"
import "../controllers"
import "bootstrap"

// Internal imports, e.g:
// import { initSelect2 } from '../components/init_select2';
// import { initCursor } from '../components/cursor';
// import { initHEXModal } from '../components/hex_modal';
// import { initINFCSlider } from '../components/infc_slider';
// import { initMethodology } from '../components/methodology';
// import { initItemToChoose } from '../components/item_to_choose';
// import { initLiveEditor } from '../components/live_editor';
// import { initTableauHexSearchBar } from '../components/tableau_hex_search_bar';
// import { initMoveArticleParts } from '../components/move_article_parts';

document.addEventListener('turbolinks:load', () => {
    // Call your functions here, e.g:
    // initCursor();
    // initHEXModal();
    // initINFCSlider();
    // initMethodology();
    // initItemToChoose();
    // initLiveEditor();
    // initTableauHexSearchBar();
    // initMoveArticleParts();
    // initSelect2();
});